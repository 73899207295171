import {
  ApolloClient,
  HttpLink,
  split,
  InMemoryCache,
} from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"; // <-- This one uses graphql-ws
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { useUserStore } from "@/store/modules/userStore";
import { storeToRefs } from "pinia";
import { configs } from "@/configs/index";

const httpLink = new HttpLink({
  uri: `https://${configs.GRAPHQL_URL}/graphql`,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${configs.GRAPHQL_URL}/graphql`,
    shouldRetry: () => true,
    retryAttempts: Infinity,
    connectionParams: async () => {
      const { getUser } = storeToRefs(useUserStore());

      return {
        headers: {
          Authorization: `Bearer ${getUser?.value?.token || ""}`,
        },
      };
    },
  })
);

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
