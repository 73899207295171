import { gamesType } from "@/types/siteTypes";
import axios from "axios";
import { locales } from "./i8n";

export const shuffle = (coll: gamesType[]) => {
  return (
    Array(coll.length)
      .fill(null)
      // возвращаем новый массив с такой же длиной и заполняем его null
      .map((_, i) => [Math.random(), i])
      // на каждой итерации возвращаем новый массив, в котором рандомное число и индекс элемента
      .sort(([a], [b]) => a - b)
      // сортируем по рандомному элементу в порядке возрастания
      .map(([, i]) => coll[i])
  );
  // возвращаем элемент по индексу, который был отсортирован
};

export const getIpInfo = async () => {
  try {
    const { data } = await axios.get("https://api.ipify.org?format=json");
    if (data.ip) {
      const result = await axios.get(`https://ipapi.co/${data.ip}/json/`);
      return result.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
  }
};

export const getBrowserLanguage = () => {
  switch (true) {
    case navigator.language.startsWith("ru") ||
      navigator.language == "be" ||
      navigator.language == "be_BY":
      return "ru";
    case navigator.language.startsWith("uk"):
      return "ua";
    case navigator.language.startsWith("kk") &&
      !navigator.language.startsWith("kkj"):
      return "kk";
    case navigator.language.startsWith("pt"):
      return "pt";
    case navigator.language.startsWith("es"):
      return "es";
    default:
      return "en";
  }
};
