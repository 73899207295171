import { createI18n } from "vue-i18n";
import { get } from "lodash";
import { api } from "@/http/api";
import { getBrowserLanguage, getIpInfo } from "@/helper";
import { configs } from "@/configs";

export const locales = [
  // { key: "en", fileName: "en_EN" },
  { key: "ru", fileName: "ru_RU" },
  // { key: "pt", fileName: "pt_BR" },
  // { key: "es", fileName: "es_ES" },
  // { key: "ua", fileName: "uk_UA" },
  // { key: "kk", fileName: "kk_KZ" },
];

const browserLanguage = getBrowserLanguage();
// const ipInfo = await getIpInfo()

// const ipLanguage = locales.find(locale => ipInfo.languages.match(locale.key)
// )

// if(ipLanguage){
//   setTimeout(() => {
//     i18n.global.locale.value = ipLanguage.key
//   }, 200);
// }

const i18n = createI18n({
  // Prevent '@' character from being used as linked messages
  messageResolver: (obj, path) => {
    if (typeof get(obj, path, "") === "string") {
      return get(obj, path, "").replaceAll(/@/g, "{'@'}");
    }
    return null;
  },
  locale: "ru",
  fallbackLocale: "ru",
  legacy: false,
  globalInjection: true,
  messages: {},
});

// if(ipLanguage){
//   setTimeout(() => {
//     i18n.global.locale.value = ipLanguage.key
//   }, 200);
// }

const cachedTranslationsJson = localStorage.getItem("translations");
const userLanguage = localStorage.getItem("userLanguage");

if (cachedTranslationsJson) {
  try {
    const locale = locales.some((locale) => locale.key.match(browserLanguage));

    if (userLanguage) {
      i18n.global.locale.value = userLanguage;
    } else if (locale) {
      i18n.global.locale.value = browserLanguage;
    }
    const cachedTranslations: { [key: string]: string } = JSON.parse(
      cachedTranslationsJson
    );

    for (const key in cachedTranslations) {
      i18n.global.mergeLocaleMessage(key, cachedTranslations[key]);
    }
  } catch (error) {
    console.error(error);
  }
}

const localesToCache: { [key: string]: string } = {};

const findLang = (key: string) => {
  let lang = locales.find((item) => item.key === key.substring(0, 2));

  if (lang === undefined) {
    lang = locales.find((item) => item.key === "en");
  }

  return lang;
};

const curLang = "ru";

const apiPromises = () => {

  return api
    .get(
      `https://${window.location.host}/public/frontendLanguages/${
        findLang(curLang).fileName
      }.json`
    )
    .then((res) => {
      const browserLocale = locales.some((_locale) =>
        _locale.key.match(browserLanguage)
      );
      if (userLanguage) {
        i18n.global.locale.value = userLanguage;
      } else if (browserLocale) {
        i18n.global.locale.value = browserLanguage;
      }
      i18n.global.mergeLocaleMessage(curLang, res.data);
      localesToCache[curLang] = res.data;
    });
};

Promise.all(apiPromises)
  .then(() => {
    try {
      localStorage.setItem("translations", JSON.stringify(localesToCache));
    } catch (error) {
      console.error(error);
    }
  })
  .catch((error) => {
    console.error(error);
  });

export default i18n;
