import axios, { AxiosInstance } from "axios";
import { useUserStore } from "@/store/modules/userStore";
import { configs } from "@/configs/index";

export const api: AxiosInstance = axios.create({
  baseURL: configs.API_URL,
  // baseURL: "http://localhost:3000/",
});
// export const image_url = "https://localhost:3000";

api.interceptors.request.use((config) => {
  const userStore = useUserStore();
  const visitorId = localStorage.getItem("visitorId");

  if (userStore.user !== null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.Authorization = "Bearer " + userStore.user.token;
  }

  if (visitorId !== null) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.common["X-visitor-id"] = visitorId;
  }

  return config;
});
