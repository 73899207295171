interface Configs {
  API_URL: string;
  GRAPHQL_URL: string;
  IMAGE_URL: string;
  CDN: string;
  VITE_IMAGE_URL_SHORT: string;
}

export const configs: Configs = {
  API_URL: import.meta.env.VITE_API_BASE_URL,
  GRAPHQL_URL: import.meta.env.VITE_GRAPHQL_BASE_URL,
  IMAGE_URL: import.meta.env.VITE_SLOTS_IMAGE_URL,
  CDN: import.meta.env.VITE_CDN,
  VITE_IMAGE_URL_SHORT: import.meta.env.VITE_IMAGE_URL_SHORT,
};
