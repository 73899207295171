<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-vue-v3";

const { data, error, getData } = useVisitorData(
  { extendedResult: true },
  { immediate: false }
);

const checkWebPSupport = () => {
  const elem = document.createElement("canvas");

  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }

  return false;
};

const detectOS = () => {
  const platform = navigator.platform;
  if (platform.indexOf("Win") !== -1) document.body.classList.add("windows-os");
  if (platform.indexOf("Mac") !== -1) document.body.classList.add("mac-os");
  if (platform.indexOf("Linux") !== -1) document.body.classList.add("linux-os");

  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  if (iosPlatforms.indexOf(platform) !== -1) {
    document.body.classList.add("ios-os");
  }
};

const detectBrowser = () => {
  if (navigator.userAgent.search("OPR") >= 0) {
    document.body.classList.add("opera-browser");
  } else if (navigator.userAgent.search("YaBrowser") >= 0) {
    document.body.classList.add("yandex-browser");
  } else if (navigator.userAgent.search("Edg") >= 0) {
    document.body.classList.add("edge-browser");
  } else if (navigator.userAgent.search("MiuiBrowser") >= 0) {
    document.body.classList.add("miui-browser");
  } else if (navigator.userAgent.search("Chrome") >= 0) {
    document.body.classList.add("chrome-browser");
  } else if (navigator.userAgent.search("Firefox") >= 0) {
    document.body.classList.add("firefox-browser");
    document.getElementsByTagName("html")[0].classList.add("firefox-browser");
  } else if (navigator.userAgent.search("Trident") >= 0) {
    document.body.classList.add("ie-browser");
  } else if (
    navigator.userAgent.search("Safari") >= 0 &&
    navigator.userAgent.search("Chrome") < 0
  ) {
    document.body.classList.add("safari-browser");
  } else {
    document.body.classList.add("unknown-browser");
  }
};

const generateOwnVisitorId = () => {
  var length = 20,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

watch(data, (currentData) => {
  if (currentData) {
    localStorage.setItem("visitorId", currentData.visitorId);
  }
});

watch(error, () => {
  if (error) {
    const customVisitorId = generateOwnVisitorId()
    localStorage.setItem("visitorId", customVisitorId);
  }
});

onMounted(() => {
  const webpSupported = checkWebPSupport();
  const bodyClass = webpSupported ? "webp-supported" : "webp-not-supported";
  document.body.classList.add(bodyClass);
  detectOS();
  detectBrowser();
  const savedVisitorID = localStorage.getItem("visitorId");

  if (savedVisitorID === null) {
    getData({ ignoreCache: true });
  }
});
</script>
